.branding {
  padding-top: 1rem;
}

.branding-text {
  display: flex;
  justify-content: center;
  font-size: 2rem;
  color: rgb(48, 47, 47);
}
