.menu {
  padding-top: 1.1rem;
  list-style: none;
  text-align: center;
}

.menu-item {
  display: inline;
  justify-content: center;
  margin-right: 10px;
  margin-left: 10px;
  font-size: 1.2rem;
  cursor: pointer;
  color: rgb(48, 47, 47);
  text-decoration: none;
}

.black-bg {
  background-color: white;
  color: rgb(48, 47, 47);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  border-style: solid;
  border-width: 2px;
  border-color: rgb(48, 47, 47);
}

.black-bg:hover {
  color: white;
  background-color: rgb(48, 47, 47);
  border-style: solid;
  border-width: 2px;
  border-color: rgb(48, 47, 47);
}
