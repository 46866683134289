.cont-portfolio {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.picture-box {
  width: 300px;
  height: 300px;
  overflow: hidden;
  margin: 2px;
}

.picture {
  width: 300px;
  height: 300px;
  object-fit: cover;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.picture:hover {
  transform: scale(1.2);
  transition: all 1s ease-in-out;
}

@media (max-width: 700px) {
  .picture-box {
    width: 100%;
    margin: 1px;
  }

  .picture {
    width: 100%;
    object-fit: cover;
  }
}
