.gallery {
  display: flex;
  width: 100%;
  height: 100%;
  background-color: #1f1e1e;
}

.imageGallery {
  width: 100%;
  height: auto;
  object-fit: contain;
}

.arrow {
  position: absolute;
  top: 45%;
  padding: 5px;
  background-color: white;
  opacity: 0.2;
  border-radius: 7px;
}

.previous {
  right: 10px;
}

.next {
  left: 10px;
}

.close {
  top: 10px;
  right: 10px;
  padding-left: 10px;
  padding-right: 10px;
}
