.alert {
  margin-top: 35px;
  text-align: center;
}

.header {
  margin-top: 35px;
  text-align: center;
}

.contact-form {
  color: rgb(48, 47, 47);
  margin-left: 30%;
  margin-right: 30%;
}

.textInput {
  padding-left: 5px;
}

#name {
  width: 100%;
  height: 20px;
  margin-top: 5px;
  border-color: rgb(48, 47, 47);
  border-style: solid;
  border-width: 1px;
}

#email {
  width: 100%;
  height: 20px;
  margin-top: 5px;
  border-color: rgb(48, 47, 47);
  border-style: solid;
  border-width: 1px;
}

#message {
  width: 99%;
  height: 200px;
  margin-top: 5px;
  border-color: rgb(48, 47, 47);
  border-style: solid;
  border-width: 1px;
}

#btn-submit {
  cursor: pointer;
  background-color: white;
  color: rgb(48, 47, 47);
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 5px;
  padding-bottom: 5px;

  border-style: solid;
  border-width: 2px;
  border-color: rgb(48, 47, 47);
  font-size: 1.2rem;
}

#btn-submit:hover {
  color: white;
  background-color: rgb(48, 47, 47);
  border-style: solid;
  border-width: 2px;
  border-color: rgb(48, 47, 47);
}

@media (max-width: 1000px) {
  .contact-form {
    margin-left: 20%;
    margin-right: 20%;
  }
}

@media (max-width: 700px) {
  .contact-form {
    margin-left: 10%;
    margin-right: 10%;
  }
}

@media (max-width: 500px) {
  .contact-form {
    margin-left: 2%;
    margin-right: 2%;
  }
  #btn-submit {
    width: 100%;
  }
}
