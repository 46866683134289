.footer-text {
  padding-bottom: 10px;
  display: flex;
  justify-content: center;
  font-size: 1.2rem;
  color: rgb(48, 47, 47);
}

.footer-icon {
  display: flex;
  justify-content: center;
  padding-top: 20px;
  padding-bottom: 10px;
  color: rgb(48, 47, 47);
}

.footer-icon i {
  padding-left: 10px;
  padding-right: 10px;
  color: rgb(48, 47, 47);
}
