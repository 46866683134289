:root {
  --speed: 1000ms;
  --bg-color: rgba(0, 0, 20, 0.75);
  --bg-color-hover: rgba(0, 0, 20, 0.2);
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Franklin Gothic Medium", "Arial Narrow", Arial, Helvetica,
    sans-serif;
  width: 100%;
  height: 100%;
}

h1 {
  font-size: 4rem;
  color: #fff;
  position: relative;
  display: block;
  text-align: center;
  top: 20%;
  white-space: nowrap;
}

.button-start {
  display: block;
  position: relative;
  left: 50%;
  top: 50%;
  height: 2.6rem;
  padding-top: 1.3rem;
  width: 15rem;
  text-align: center;
  color: #fff;
  border: #fff solid 0.2rem;
  font-size: 1rem;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: none;
  transform: translateX(-50%);
}

.button-start:hover {
  background-color: rgba(255, 255, 255, 0.2);
}

.main-container {
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
  background: var(--bg-color);
  overflow: hidden;
}

.split {
  flex: 1 1 auto;
  height: 100%;
  overflow: hidden;
}

.split:hover {
  flex: 3 1 auto;
}

/* SPLIT */
/* LEFT */
#left-img {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
}

#bg-left {
  position: relative;

  width: 100%;
  height: 100%;
  background: var(--bg-color);
  transition: var(--speed) all ease-in-out;
}

#bg-left:hover {
  background: var(--bg-color-hover);
  transition: var(--speed) all ease-in-out;
}

#center-img {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
}

#bg-center {
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--bg-color);
  transition: var(--speed) all ease-in-out;
}

#bg-center:hover {
  background: var(--bg-color-hover);
  transition: var(--speed) all ease-in-out;
}

/* RIGHT */

#right-img {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
}

#bg-right {
  position: relative;
  width: 100%;
  height: 100%;
  background: var(--bg-color);
  transition: var(--speed) all ease-in-out;
}

#bg-right:hover {
  background: var(--bg-color-hover);
  transition: var(--speed) all ease-in-out;
}

.split {
  transition: var(--speed) all ease-in-out;
}

@media (max-width: 800px) {
  h1 {
    font-size: 2rem;
  }
  .button-start {
    width: 6rem;
    height: 1.6rem;
    font-size: 0.8rem;
    padding-top: 0.7rem;
  }
}

@media (max-height: 700px) {
  .button-start {
    top: 70%;
  }
}
