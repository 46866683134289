.category ul {
  list-style: none;
  text-align: center;
}

.category ul li {
  display: inline;
  justify-content: center;
  margin-right: 40px;
  font-size: 1.5rem;
  cursor: pointer;
  color: rgb(48, 47, 47);
}

.active {
  text-decoration: underline;
}

.categoryLink {
  text-decoration: none;
  color: rgb(48, 47, 47);
}

@media (max-width: 700px) {
  .category ul li {
    display: block;
  }
}
